.root {
  min-height: "100vh";
}

.purplebackground {
  background-color: #563d7c !important;
  color: white !important;
}
.selectedpurplebackground:hover {
  background-color: #341f54 !important;
  color: white !important;
}
.blueblackbackground {
  background-color: #29377e !important;
  color: white !important;
}
.ligt-blue-nav-bar {
  background-color: #ccdfff !important;
  color: white !important;
}
.active {
  color: rgb(131, 149, 201);
  cursor: pointer;
}

.selectedblueblackbackground {
  background-color: #e9faf0 !important;
  color: #29377e !important;
}
.selectedNavButton {
  text-decoration: underline !important;
}
.chatInputBottom {
  position: absolute;
  bottom: 0;
}
.redAI {
  background-color: #fc3e5a !important;
  color: white !important;
}
.yellowAI {
  background-color: #f9ffb6 !important;
  color: black !important;
}
.greenAI {
  background-color: #b4e8cf !important;
  color: white !important;
}
.chat {
  background-color: brown !important;
  color: white;
  border-radius: 10%;
  padding: 2px;
  margin: 1px;
  display: inline-block;
}
.icon {
  cursor: pointer;
}
.navnormal {
  color: rgb(15, 15, 15) !important;
}
.navactive {
  font-weight: bold !important;
  color: #29377e !important;
}

th {
  border: 0px solid !important;
  background-color: #ffffff !important;
}

atr {
  border: 0;
  display: block;
  margin: 5px;
}
.asolid {
  border: 1px #b4c6e0 solid;
  border-radius: 10px;
}

tr {
  cursor: pointer;
}

.fontbold {
  font-weight: bold;
}

.fix-table-size {
  min-width: 1000px !important;
}
.bg-white {
  background-color: white !important;
}

.bg-grey {
  background-color: rgb(240, 239, 239) !important;
}

.color-light-green {
  color: #e9faf0 !important;
}
.color-mid-green {
  color: #b4e8cf !important;
}
.color-dark-green {
  color: #27ca6d !important;
}
.color-dark-red {
  color: #fc3e5a !important;
}
.color-light-red {
  color: #ffecee !important;
}
.color-light-yellow {
  color: #f9ffb6 !important;
}
.colort-dark-yellow {
  color: #f2c84c !important;
}
.color-certify-blue {
  color: #ccdfff !important;
}
.footer {
  height: 50px;
  position: relative !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  background-color: #29377e !important;
  color: white !important;
  text-align: center !important;
  right: 0px;
  margin-bottom: 0px;
}
tr:hover {
  background-color: #f0f0f0 !important;
  /* background-color: #ccdfff !important; */
}
.table-all {
  /* background-color: #f5f5f5 !important; */
  /*background-color: #f0f0f0 !important;*/
  /* background-color: #ccdfff !important; */
  /* background-color: rgb(236, 236, 236) !important; */
  border-radius: 10px !important;
}
.button-delete {
  background-color: #29377e !important;
  color: white !important;
}
.button-delete-darker {
  background-color: #142c50 !important;
  color: black !important;
}
.save-private-rules {
  background-color: #29377e !important;
  color: white !important;
}

.border-bottomLine {
  border-bottom: 2px #29377e solid;
  margin-bottom: 0px;
}

.content-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  color: #d0dbf0;
}

.call_button {
  border: 1px solid;
  border-radius: 20;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 75%;
  margin-right: 10px;
}

.buttons {
  /* color: rgba(141, 141, 142, 1); */
  color: rgba(90, 188, 42, 1);
  font-size: 18px;
  padding-left: 20;
  padding-right: 20;
  border: 1px solid;
  border-radius: 10px;
  /* font-size: 16px; */
  /* position: relative; */
  /* top: 20%; */
  /* background-color: rgba(255, 255, 255, 1); */
  /* color: rgba(72, 115, 234, 1); */
  /* box-shadow: 0px 0px 10px 0px gray, 0px 0px 10px 5px rgba(72, 115, 234, 0.5); */
  /* border: none; */
  /* border-radius: 3px; */
  /* padding: 8px; */
  /* padding-left: 12px; */
  /* padding-right: 12px; */
  /* cursor: pointer; */
  /* transition: background-color 0.4s; */
}

.repeat-button {
  color: rgba(141, 141, 142, 1);
}

.buttons:hover {
  /* color: rgba(90, 188, 42, 1); */
  color: rgba(34, 139, 34, 1);
  /* background: rgb(237, 241, 236); */
  /* box-shadow: 0px 0px 10px 0px gray, 0px 0px 10px 5px rgba(40, 54, 128, 1); */
  /* cursor: pointer; */
}

.repeat-button:hover {
  color: #29377e;
}

.buttons:disabled {
  color: rgba(141, 141, 142, 1);
}

.buttonOriginal {
  box-shadow: 2px 2px 10px 1px #e6e3e3;
  background: #d6d9e0;
  color: #29377e;
  border: none;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
a {
  color: #29377e !important;
}
a.active {
  color: #3b53ca !important;
}
td {
  color: rgb(49, 48, 48);
}

.header {
  /* background: linear-gradient(
    90deg,
    rgba(72, 180, 234, 1) 0%,
    rgba(72, 115, 234, 1) 100%
  );
  color: #ffffff;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  height: 85px; */
}

.setupStepItem {
  /* z-index: 9; */
  /* background-color: #ffffff; */
  /* height: 80px; */
  /* border-radius: 10px; */
  font-weight: 500;
  color: rgba(141, 141, 142, 1);
  margin-right: 50px;
  /* margin-left: 25px; */
  /* margin: auto; */
  font-size: 18px;
}

.currentSetupStepItem {
  /* height: 120px; */
  /* box-shadow: 0px 0px 10px 0px gray, 0px 0px 10px 5px rgba(72, 115, 234, 0.5); */
  color: rgba(40, 56, 141, 1);
  font-weight: bold;
  border-radius: 20px;
}

.currentSetupContent {
  margin-top: 10px;
  /* background-color: rgba(218, 233, 244, 1); */
  /* padding-top: 50px; */
  /* min-height: calc(100% - 105px); */
  /* width: calc(100% - 60px); */
  /* margin: auto; */
  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.systemCheckNavigation {
  /* background-color: rgba(255, 255, 255, 1); */
  height: 30px;
  /* color: rgba(40, 54, 128, 1); */
  color: rgba(141, 141, 142, 1);
  font-weight: 500;
  text-align: center;
  /* font-weight: bold; */
  /* border-radius: 10px; */
  /* width: 200px; */
  /* margin: 0px; */
  /* box-shadow: 0px 0px 10px 0px gray, 0px 0px 10px 5px rgba(72, 115, 234, 0.5); */
}

.proceed-item {
  color: rgba(141, 142, 157, 1);
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
}

.finished-item {
  color: rgba(90, 188, 42, 1);
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
}

.systemCheckAllStepsItemBorder {
  /* border-bottom-width: 3px; */
  /* border-bottom-style: solid; */
  /* border-bottom-color: rgba(40, 54, 128, 1); */
  /* border-radius: 4px; */
}

.terms-condition-panel {
  /* margin-bottom: 30px; */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(40, 54, 128, 1);
  /* background-color: rgba(237, 241, 236, 0.5); */
  border-radius: 20px;
  box-shadow: rgba(22, 31, 39, 0.42) 0px 0px 10px 5px,
    rgba(19, 26, 32, 0.08) 0px 0px 10px 5px;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}
.terms-condition-panel-smartlock-modal {
  /* margin-bottom: 30px; */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(40, 54, 128, 1);
  /* background-color: rgba(237, 241, 236, 0.5); */
  border-radius: 20px;

  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}
.terms-condition-panel-smartlockmac-modal {
  /* margin-bottom: 30px; */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(40, 54, 128, 1);
  /* background-color: rgba(237, 241, 236, 0.5); */
  border-radius: 20px;

  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}
.modal-content{
  box-shadow: rgba(106, 127, 145, 0.42) 0px 0px 10px 5px, rgb(19 26 32 / 8%) 0px 0px 10px 5px;
  border-radius: 40px !important;
}

.descriptionCover {
  /* margin-bottom: 30px; */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(40, 54, 128, 1);
  /* background-color: rgba(237, 241, 236, 0.5); */
  border-radius: 20px;
  box-shadow: rgba(22, 31, 39, 0.42) 0px 0px 10px 5px,
    rgba(19, 26, 32, 0.08) 0px 0px 10px 5px;
  font-size: 16px;
}

.fixedHeight {
  height: 330px;
}

.singleFixedHeight {
  height: 450px;
}

.multiFixedHeight {
  height: 500px;
}

.fixedWidth {
  min-width: 720px;
}

.cameraMaxWidth {
  max-width: 576px;
}

.titleColor {
  color: rgba(40, 54, 128, 1);
}

.font-size-small {
  font-size: 12px;
}

.font-size-medium {
  font-size: 16px;
}

.font-size-large {
  font-size: 20px;
}

.font-size-xlarge {
  font-size: 24px;
}
